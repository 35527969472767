//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CChartLineSimple, CChartBarSimple } from '../charts'

export default {
  name: 'WidgetsDropdown',
  components: { CChartLineSimple, CChartBarSimple },
  props: {
    summary: {
      all_contract: "0",
      new_contract: "0",
      balance: "0",
      customers: "0"
    }
  },
  mounted() {
    console.log(this.summary)
  }
}
